const { createStore: createReduxStore, applyMiddleware } = require('redux')
const { breadcrumbsMiddleware } = require('../logger')
const reduxSerialEffects = require('redux-serial-effects')
const rootReducer = require('./rootReducer')
const createBreadcrumbMessage = require('../helpers/createBreadcrumbMessage')

const configureStore = (logger, datasetId) => {
  const {
    middleware: serialEffectsMiddleware,
    subscribe
  } = reduxSerialEffects.createMiddleware()

  const store = createReduxStore(
    rootReducer,
    undefined,
    applyMiddleware(
      serialEffectsMiddleware,
      breadcrumbsMiddleware(logger, breadCrumb =>
        Object.assign({}, breadCrumb, {
          message: createBreadcrumbMessage(breadCrumb.message, datasetId)
        })
      )
    )
  )

  return {
    store,
    subscribe
  }
}

module.exports = configureStore
