'use strict'
const omit_ = require('lodash/omit')

const id = x => x

module.exports = (
  logger,
  breadCrumbCallback = id
) => store => next => action => {
  logger.breadcrumb(
    breadCrumbCallback({
      category: 'redux',
      message: action.type,
      data: omit_(action, 'type', 'record')
    })
  )

  return next(action)
}
